import React, { useEffect } from "react"
import Layout from "./layout"
import ProductHero from "./product-hero"
import AgenciesMarquee from "./agencies-marquee"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import SEO from "./seo"

export default ({ data }) => {
  const post = data.allWordpressPost.edges[0].node
  return (
    <Layout>
      <SEO
        title={post.title}
        image={post.acf.hero?.localFile.childImageSharp.fixed.src}
        pageType="article"
      />
      <ProductHero
        title={[<span dangerouslySetInnerHTML={{ __html: post.title }}></span>]}
      />
      {post.acf.hero && post.acf.hero.localFile && (
        <div className="page-hero-image">
          <div className="container dark">
            <Img
              fixed={post.acf.hero.localFile.childImageSharp.fixed}
              style={{ display: "block", width: "100%" }}
            />
            <footer>
              <div>
                <p className="is-size-7">POSTED BY INPOWERED</p>
                <p className="is-size-7 has-text-weight-medium">
                  {[post.date]}
                </p>
              </div>
            </footer>
          </div>
        </div>
      )}
      <div class="container px-6">
        <div
          className="blog-post-container wp-text"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>
      <AgenciesMarquee />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    allWordpressPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          content
          slug
          date(formatString: "LLLL")
          author
          acf {
            hero {
              localFile {
                childImageSharp {
                  fixed(height: 500, width: 1120, quality: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
